<script>
export default {
    props:["save","ODS","DP","block","select","service_order","canEdit",'reset','Cat'],
    data() {
       return {
           has:false,
           show:false,
           up:false,
       }
    },
    watch:{
        save:{
            handler(val){
                if(!val) this.has =val;
                // this.resetEmit();
            }
        },
        reset:{
            handler(val){
                if(val) this.resetEmit();
                
            }
        },
        ODS:{
            handler(val){
                // console.log(val);
            }
        }
    },
    methods:{
        addEmit(){
            this.$emit("add",true);
            this.has = true
            
        },
        saveEmit(){
            this.$emit("save",true);
        },
        saveEmitPending(){
            this.$emit("saveToPending",true);
        },
        editEmit(){
            this.$emit("edit",true);
            this.up = true;
        },
        SaveEditEmit(){
            this.$emit("SaveEdit",true);
            // console.log('Save Edit');
            this.up = false;
        },
        duplicateEmit(){
            this.$emit("duplicate",true);
        },
        printEmit(){
            this.$emit("print",true);
        },
        deleteEmit(){
            this.$emit("delete",true);
        },
        resetEmit(){
            this.$emit("resetEmit",true);
            this.$emit("add",false);
            this.$emit("edit",false);
            this.$emit("delete",false);
            this.has = false;
            this.up = false;
        },
        validateEmit(){
            this.$emit("validate",true);
        },
        confirmEmit(){
            this.$emit("confirm",true);
        },
        rejectEmit(){
            this.$emit("reject",true);
        },
    }
}
</script>
<template>
    <div>
        
        <b-tooltip v-if="!has" target="addBtn" title="Ajouter" placement="left" triggers="hover"></b-tooltip>
        <b-tooltip v-if="!canEdit" target="editBtn" title="Modifier" placement="left" triggers="hover"></b-tooltip>

        <ul id="Sticky">
            <li>
                <button  id="addBtn" type="button" :disabled="block || select || has" @click="addEmit" class="btn btn-add rounded-pill float-right mr-1 mt-1">
                    <i class="fas fa-plus text-white"></i>
                </button>
            </li>
            <!-- <li v-else>
                <button v-b-tooltip.hover.left="'Annuler'" type="button" @click="resetEmit" class="btn btn-reset rounded-pill float-right mr-1 mt-1">
                    <i class="fas fa-times-circle text-white"></i>
                </button>
            </li> -->
            <li v-if="(has && (ODS == 1)) || (ODS == 1 && select && service_order.status == 'draft')">
                <button v-b-tooltip.hover.left="'Enregistré pour confirmation'" type="button" :disabled="block || service_order.status =='approved'" @click="saveEmitPending" class="btn btn-cash rounded-pill float-right mr-1 mt-1">
                    <i class="far fa-paper-plane text-white"></i>
                </button>
            </li>
            <li v-if="ODS == 1 && service_order.status == 'pending_confirmation'">
                <button v-b-tooltip.hover.left="'Confirmer'" type="button" :disabled="block || !select" @click="confirmEmit" class="btn btn-confirm rounded-pill float-right mr-1 mt-1">
                    <i class="fas fa-check text-white"></i>
                </button>
            </li>
            <li v-if="ODS == 1 && service_order.status == 'pending_confirmation'">
                <button v-b-tooltip.hover.left="'Rejeter'" type="button" :disabled="block || !select" @click="rejectEmit" class="btn btn-reject rounded-pill float-right mr-1 mt-1">
                    <i class="fas fa-ban text-white"></i>
                </button>
            </li>
            <li v-if="ODS == 1 && service_order.status == 'confirmed'">
                <button v-b-tooltip.hover.left="'Valider'" type="button" :disabled="block || !select" @click="validateEmit" class="btn btn-validate rounded-pill float-right mr-1 mt-1">
                    <i class="fas fa-check-double text-white"></i>
                </button>
            </li>
            <!-- <li v-if="ODS == 1">
                <button v-b-tooltip.hover.left="'Dupliquer'" type="button" :disabled="block || !select" @click="saveEmit" class="btn btn-duplicate rounded-pill float-right mr-1 mt-1">
                    <i class="far fa-copy text-white"></i>
                </button>
            </li> -->
            <li v-if="DP == 1 || Cat == 1">
                <button v-b-tooltip.hover.left="'Dupliquer'" type="button" :disabled="canEdit" @click="duplicateEmit" class="btn btn-duplicate rounded-pill float-right mr-1 mt-1">
                    <i class="far fa-copy text-white"></i>
                </button>
            </li>
            
            <li v-if="ODS == 1 && service_order &&service_order.status == 'approved'">
                <button v-b-tooltip.hover.left="'Imprimer'" type="button" @click="printEmit" class="btn btn-print rounded-pill float-right mr-1 mt-1">
                    <i class="mdi mdi-printer text-white"></i>
                </button>
            </li>
            
            <li>
                <button  id="editBtn" type="button" :disabled="block || canEdit" @click="editEmit" class="btn btn-edit rounded-pill float-right mr-1 mt-1">
                    <i class="fas fa-pencil-alt text-white" style=""></i>
                </button>
            </li>
            <!-- <li v-else>
                <button  v-b-tooltip.hover.left="'Enregister Modification'" type="button" :disabled="block" @click="SaveEditEmit" class="btn btn-edit rounded-pill float-right mr-1 mt-1">
                    <i class="far fa-save text-white" style=""></i>
                </button>
            </li> -->
            <li>
                <button v-b-tooltip.hover.left="'Supprimer'" type="button" :disabled="block || canEdit" @click="deleteEmit" class="btn btn-delete rounded-pill float-right mr-1 mt-1">
                    <i class="far fa-trash-alt text-white"></i>
                </button>
            </li>
            
        </ul>

    </div>
    
    
</template>
<style scoped>
@media screen and (-webkit-min-device-pixel-ratio:0) {

    /*Chrome CSS here*/
    #Sticky {
    position: fixed;
    right: 0;
    top: 50%;
    width: 4em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
    
}

@supports (-moz-appearance:none){ 
  #Sticky {
    position: fixed;
    right: 0;
    top: 50%;
    width: 1em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
}
  

  .rounded-pill{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50%;
  }
  .btn-cash{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #50A5F1 ;
  }
  .btn-add{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #002A59 ;
  }
  .btn-duplicate{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-confirm{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-reject{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: red ;
  }
  .btn-validate{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #00708F ;
  }
  .btn-edit{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color:  #FFB236 ;
  }
  .btn-delete{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-reset{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #9a0c26;
  }
  .btn-upload{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #6B8B9C ;
  }
  .btn-download{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #A6C1CF;
  }
  .btn-print{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }

  /* Style the links inside the sidenav */
#mySidenav a {
  position: fixed; /* Position them relative to the browser window */
  left: -730px; /* Position them outside of the screen */
  /* transition: 0.3s; Add transition on hover */
  padding: 5px; /* 15px padding */
  width: 750px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  font-size: 14px; /* Increase font size */
  color: white; /* White text color */
  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
  z-index: 999;
  transition: 1.5s;
}

#mySidenav a:hover {
  left: 0; /* On mouse-over, make the elements appear as they should */
}

/* The about link: 20px from the top with a green background */
#about {
  bottom: 130px;
  background-color: #04AA6D;
}

</style>